import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { BlogPage } from "../components/BlogPage"
import { Breadcrumbs } from "../components/Breadcrumbs"
import { InternalHeader } from "../components/InternalHeader"

const Blog = ({ pageContext }) => {
  console.log("pageContext:", pageContext)
  const {
    title,
    content,
    date,
    featuredImage,
    blogHeaderImage,
    previewSQ,
  } = pageContext

  return (
    <Layout preview={previewSQ}  pageContext={pageContext}>
      <SEO title={title} />
      <InternalHeader featuredImage={featuredImage} title={title} />
      <Breadcrumbs title={title} blog />
      <BlogPage
        blogHeaderImage={blogHeaderImage.image}
        title={title}
        date={date}
        content={content}
      />
    </Layout>
  )
}

export default Blog
