import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentTest from "../components/ContentTest"
import { Breadcrumbs } from "../components/Breadcrumbs"
import { InternalHeader } from "../components/InternalHeader"
import { FAQ } from "components/FAQ"

const Internal = ({ pageContext }) => {
  const {
    featuredImage,
    title,
    content,
    pageSettings,
    legAncestors,
    previewSQ,
  } = pageContext

  return (
    <Layout featuredImage={featuredImage} pageContext={pageContext} preview={previewSQ}>
      <SEO title={title} />
      <InternalHeader featuredImage={featuredImage} title={title} />
      <Breadcrumbs title={title} data={legAncestors} />
      <ContentTest content={content} />
      {pageSettings.showFaqs && <FAQ />}
    </Layout>
  )
}

export default Internal
