import React from "react"
import { Content } from "../components/Content"
import { MainColumn } from "components/MainColumn"

const ContentTest = ({ content }) => {
  return (
    <MainColumn>
      <Content blog content={content}>
        <p>
          Auburn Primary School has a range of programs to support eque porro
          quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
          adipisci veli.
        </p>

        <p>
          Mauris pulvinar massa orci, ac dictum ex elementum iaculis. Vesim
          enstibu lutibulum ornt nibh, non pulvinar orci molestie in. Curabitur
          posuere vestibulum diam, ut cursus purus convallis vel. Cras eu
          plaasdcerat augue. Donec ac elit ipsum. Phasellus ves ehicula tehicula
          tondimentum ertor vel nisl friimen ngilla aliquet. Nullaadasm semper,
          dolor et eleifenleifend luctus, justo ondimentum eimenrat, ac ls,
          justo ondndime ntumimen eimentimenum erat, ac laoreet mauris arcu sed
          erat. Aenean id vives vaehicula tortor vel nimenisl fringilla.
        </p>

        <figure className="wp-block-image alignright">
          <img />
        </figure>

        <p>
          Nec leo rhoncus conseurabitur quis velit maximus arcadu aliquet
          vehicula rhoncus nec ex. Sed congue varius ante. Etiamasd viverra odio
          vel dolor luctus hendrerit. Sed in egestas tortor. Phasellus rmentum.
          Mauris quis turps vel dolor luctus ante. Etiam viverra odio vel dolor
          l turpis nisi. Mauris quis tempus maximimenus are e varius ante. Etiam
          viverra odio vel dolor luctus e varius ante. Etiam viverra odio vel
          dolor luctus ante. Etia luctus ante. Etiam viverra odio vel dolor lel
          dolor luctus cu aliquet vehicula rhoncus nec ex. Sed congue viverra
          odio vel dasad.
        </p>

        <h3>Mauris quis turps</h3>

        <p>
          Mauris pulvinar massa orci, elementac dictum ex elementum iaculis.
          Vestibulum ornt nibh, non pulvinar orci molestie in. Curabitur posuere
          vestibulum diam, ut cursus t. Aenean id vives vaehicula tortor vel
          nisl asellus ves ehicend luctus, justo ondiurus convallis vel. Cras eu
          plaasdcerat augue. Donec ac elit ipsum. Phasellus veelements ehicula
          tehicula tortor vel nisl fringilla aliquet. Nullaadasm semper, dolor
          et eleifend luctus, justo ondimentum erat, ac laoreet mauris arcu sed
          erat. Aenean id vives vaehicula tortor vel nisl fringilla. Donec ac
          elit ipsum. Phasellus ves ehicula tehicula tortor vdklhasdel nisl
          fringilla asat. Nullaadasm semper, dolor et eleifend luctus, justo
          ondimentum erat, ac laoreet mauris end luctus, justo ondit. Aenean id
          vives vaehicula tortor vel nisl asellus ves ehicend luctus, justo
          ondimentum erat, ac laoreet mauris arcu sed erat. Aenean id vives
          vaehicula tortor vel nisl asellus ves ehicend luctus, justo ondimentum
          erat, ac uris ula tehicula tortor vel nisl.
        </p>
        <blockquote>
          <p>
            Auburn Primary School set et perspiciatisolu undaea omnis
            istolunatus error sit volup sed doolu esadadius tempor incidirror
            sit volup seolud do esadiudta.
          </p>
          {/* <cite>
            Carol O'Neill <span>Current parent</span>
          </cite> */}
        </blockquote>

        <p>
          Mauris pulvinar massa orci, ac dictum ex elementum iaculis. Vesim
          enstibu lutibulum ornt nibh, non pulvinar orci molestie in. Curabitur
          posuere vestibulum diam, ut cursus purus convallis vel. Cras eu
          plaasdcerat augue. Donec ac elit ipsum. Phasellus ves ehicula tehicula
          tondimentasdnisl friimen ngilla aliquet. Nullaadasm semper, dolor et
          eleifenleifend luctus, justo ondimentum eimenrat, ac ls, justo
          ondndime ntumimen eimentimenum erat, ac laoreet mauris arcu sed erat.
          Aenean id vives vaehicula tortor vel nimenisl fringilla.
        </p>

        <div className="wp-block-button">
          <a href="" className="wp-block-button__link">
            Default State
          </a>
        </div>
      </Content>
    </MainColumn>
  )
}

export default ContentTest
