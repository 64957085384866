const imgObject = `
  sourceUrl
  srcSet
  mediaDetails {
    height
    width
  }
`

const childLinks = `
  title
  link
  slug
  childPages(where: {orderby: {field: MENU_ORDER, order: ASC}}) {
    nodes {
      title
      link
      slug
      childPages(where: {orderby: {field: MENU_ORDER, order: ASC}}) {
        nodes {
          title
          link
          slug
        }
      }
    }
  }
`

const teaser = `
  title
  excerpt
  link
  featuredImage {
    ${imgObject}
  }
`

const pageData = `
  id
  title
  excerpt
  slug
  pageTemplate
  content
  link
  date
  pageSettings {
    showFaqs
  }
  statementStrip {
    statementText
  }
  homeBannerItems {
    items {
      heading
      description
      link
      linkText
      image {
        ${imgObject}
      }
    }
  }

  homeQuickLinks {
    link
    linkText
    statement
    quicklinks {
      ... on WPGraphQL_Page {
        ${teaser}
      }

      ... on WPGraphQL_Post {
        ${teaser}
      }
    }
    
  }

  featuredImage {
    ${imgObject}
  }

  legAncestors {
    __typename
    ... on WPGraphQL_Page {
      ${childLinks}
    }
  }

`

const navQuery = ` 
  mainMenu: pages(where: {orderby: {field: MENU_ORDER, order: ASC}, notIn: "2"}) {
    nodes {
      title
      link
      childPages(where: {orderby: {field: MENU_ORDER, order: ASC}}) {
        nodes {
          title
          link
        }
      }
    }
  }
`
const footerQuery = `
 auburnOptions {
    siteSettings {
      email
      phoneNumber
      acknowledgement
      mapLink
      address
    }
  }
`

const postData = `
  id
  title
  excerpt
  slug
  content
  link
  date
  blogHeaderImage {
    image {
      ${imgObject}
    }
  }
  featuredImage {
    ${imgObject}
  }
  
  
`

module.exports = {
  teaser,
  pageData,
  postData,
  navQuery,
  footerQuery,
}
