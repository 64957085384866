import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeroHeader } from "../components/HeroHeader"
import ContentTest from "../components/ContentTest"
import { Breadcrumbs } from "../components/Breadcrumbs"
import { FAQ } from "components/FAQ"
import LandingPage from "../components/LandingPage/LandingPage"
import { InternalHeader } from "../components/InternalHeader"

const Landing = ({ pageContext }) => {
  const { previewSQ } = pageContext
  return (
    <Layout preview={previewSQ}  pageContext={pageContext}>
      <SEO title="Internal" />
      <InternalHeader title="Student Wellbeing" />
      <Breadcrumbs />
      <LandingPage pageContext={pageContext} />
    </Layout>
  )
}

export default Landing
