import React from "react"
import moment from "moment"
import AvenueImg from "components/AvenueImg"

import {
  BlogPageContainer,
  TopWrapper,
  FullImage,
  Date,
  Title,
  Text,
} from "./style"

import BlogContent from "components/BlogContent"

const BlogPage = ({ title, date, content, blogHeaderImage }) => {
  return (
    <BlogPageContainer>
      <TopWrapper>
        <FullImage fluid={blogHeaderImage} />

        <Date>{moment(date).format(" MMM DD, YYYY")}</Date>
        {/* <Title> */}
          {/* Upcoming Event: <br /> Reconciliation Week */}
          {/* {title} */}
        {/* </Title> */}
      </TopWrapper>
      <BlogContent content={content} />
    </BlogPageContainer>
  )
}

export default BlogPage
