import React from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"

import BlogTemplate from "../../templates/blog"
import { postData, navQuery, footerQuery } from "../../fragments/data"
import LoadingBars from "./Loading"

const cleanedPageData = postData.replace(/WPGraphQL_/g, "")

const GET_POSTS = gql`
query($postID: ID!) {
  templateData: post(idType: DATABASE_ID, id: $postID) {
    ${cleanedPageData}
  }
  #Static Queries
    ${navQuery}
    ${footerQuery}

}
`

const PreviewPost = ({ id }) => {
  const { loading, error, data } = useQuery(GET_POSTS, {
    variables: { postID: id },
  })

  // Static Query Data
  if (data) {
    data.templateData.previewSQ = {}
    data.templateData.previewSQ.mainMenu = data.mainMenu
    data.templateData.previewSQ.auburnOptions = data.auburnOptions
  }

  console.log("Preview DATA", data)
  return (
    <>
      {loading && <LoadingBars />}

      {data && renderTemplate(data.templateData)}

      {error && <pre>{JSON.stringify(error.networkError, null, 2)}</pre>}
    </>
  )
}

export default PreviewPost

const renderTemplate = page => {

  return <BlogTemplate pageContext={page} />

}
