import styled from "styled-components"
import { device } from "../../utils/breakpoints.js"
import { MainColumn } from "components/MainColumn"
import AvenueImg from "components/AvenueImg"

export const BlogPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
export const TopWrapper = styled(MainColumn)`
  /* margin-bottom: 50px;

  @media ${device.desktop} {
    margin-bottom: 100px;
  } */
`

export const FullImage = styled(AvenueImg)`
  width: 100%;
  background: #d8d8d8;
  margin-bottom: 25px;

  @media ${device.desktop} {
    margin-bottom: 50px;
  }
  > div {
    padding-bottom: 64.2% !important;

    @media ${device.desktop} {
      padding-bottom: 47.9% !important;
    }
  }
`

export const Date = styled.p`
  font-size: 15px;
  margin-bottom: 10px;
  @media ${device.desktop} {
    font-size: 16px;
    margin-bottom: 23px;
  }
`

export const Title = styled.h1`
  line-height: 130%;
  font-size: 26px;
  margin-bottom: 15px;
  @media ${device.desktop} {
    font-size: 36px;
    margin-bottom: 20px;
  }
`

export const Text = styled.p`
  color: ${props => props.theme.darkGreen};
  font-weight: 400;
  margin-bottom: 40px;
  font-size: 20px;
  @media ${device.desktop} {
    font-size: 22px;
    width: 66.66666%;
    margin-bottom: 80px;
  }
`
